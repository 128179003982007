import { createSlice } from '@reduxjs/toolkit'

import {
	initialStateTemplate,
	reducers,
} from '.reducers/libraries/filters.template'

const initialState = {
	...initialStateTemplate,
	actorsIds: [],
	isOpen: false,
	order: 'desc',
	sortBy: '',
	searchTerm: '',
	before: '',
	videoPosition: '',
	perPage: 50,
	is_disabled: 'false',
	currentPage: 1,
	tags: [],
	keywords: [],
	actorTags: [],
	shootDateRange: [null, null],
	companyId: window.sessionStorage.getItem('selects_currentCompanyID'),
	period: null,
	favorites: false,
	lastUpdate: 0,
	description: '',
	has_embeddings: null,
}

const selectsFilters = createSlice({
	name: 'SELECTS.FILTERS',
	initialState,
	reducers: {
		...reducers,
		setBefore(state, { payload: before }) {
			state.before = before
		},

		resetTagsFilter(state, { payload: tagToRemove }) {
			if (Array.isArray(tagToRemove)) {
				state.tags = state.tags.filter((tag) => !tagToRemove.includes(tag))
			}
		},

		setShootDateRange(state, { payload: range }) {
			state.shootDateRange = range
			state.currentPage = 1
		},

		setPeriod(state, { payload: period }) {
			state.period = period
		},

		setVideoOrientation(state, { payload: type }) {
			state.videoPosition = type
			state.currentPage = 1
		},

		toggleActorTag(state, { payload: actorTag }) {
			if (state.actorTags.find((tag) => tag === actorTag)) {
				state.actorTags = state.actorTags.filter((tag) => tag !== actorTag)
			} else {
				state.actorTags.push(actorTag)
			}
			state.currentPage = 1
		},

		clearAll(state) {
			return {
				...initialState,
				isOpen: state.isOpen,
			}
		},
	},
})

export const {
	setActorsIds,
	setIsOpen,
	setOrder,
	setSortBy,
	setPerPage,
	setSearchTerm,
	setCurrentPage,
	setCompanyId,
	setBefore,
	setFilter,
	setShootDateRange,
	setTagFilter,
	setTagsFilter,
	setKeywordsFilter,
	setPublicLink,
	setUniqueTagPerCategory,
	setPeriod,
	setDurationTagsFilter,
	toggleActorTag,
	toggleFavorites,
	toggleAllFavorites,
	setShowUntagged,
	setShowUntaggedDeliveryNumbers,
	setUntaggedCategories,
	resetLastUpdate,
	clearAll,
	resetTagsFilter,
	setVideoOrientation,
	setInitialState,
	setBrandedFilter,
	setDescription,
	setHasEmbeddingsFilter,
	setDeliveryNumbers,
	clearTags,
} = selectsFilters.actions

export default selectsFilters.reducer
